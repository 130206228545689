import classes from "./AdvantagesUser.module.scss";
import udobstvo from "../../../static/images/udobstvo.png";
import auth from "../../../static/images/auth.png";

const AdvantagesUser = () => {

    return (
        <div className={classes.AdvantagesUser}>
            <div className={classes.wrapper}>
                <div className={classes.headLine}>
                    <div className={classes.headLineContainer}>
                        <h3 className={classes.title}>
                            Преимущества
                        </h3>
                        <p className={classes.subTitle}>
                            для пользователей
                        </p>
                    </div>
                    <div className={classes.tabsWrap}>
                        <span>удобство</span>
                    </div>
                </div>

                <div className={classes.cards}>
                    <div className={classes.bigBottomCard}>
                        <div className={classes.blackInnerBorder}>
                            <div className={classes.left}>
                                <div className={classes.absence}>
                                    Отсутствие авторизации
                                </div>

                                <div className={classes.row}>
                                    <img
                                        src={auth}
                                        alt="auth"
                                    />

                                    <p>
                                        {/* Клиентам не нужно постоянно вводить логин и пароль, так как авторизация происходит через Telegram */}
                                        {/* Отпустите заботы о забытых паролях и наслаждайтесь удобством входа в систему без лишних сложностей - авторизация проходит через Telegram! */}
                                        Отпустите заботы о забытых паролях и наслаждайтесь удобством входа в систему - авторизация проходит через Telegram!

                                        {/* Прощайте проблемы с забытыми паролями и наслаждайтесь легкостью входа в систему - авторизация осуществляется через Telegram! */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.right}>
                        <h5>
                            Удобство
                        </h5>

                        <img
                            src={udobstvo}
                            alt="udobstvo"
                        />

                        <p>
                            Не нужно переходить на другие страницы или покидать Telegram. WebApp <span>работает прямо в мессенджере!</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdvantagesUser;
