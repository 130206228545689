import classes from "./Advantages.module.scss";
import anal from '../../../static/images/anal.png';
import economy from '../../../static/images/economy.png';
import MediaQuery from "react-responsive";

const Advantages = () => {
    return(
            <div className={classes.wrapper}>
                <div className={classes.content}>
                    <div className={classes.headLine}>
                        <div className={classes.headLineContainer}>
                            <h3 className={classes.title}>
                                Преимущества
                            </h3>
                            <p className={classes.subTitle}>
                                для бизнеса
                            </p>
                        </div>
                        <div className={classes.tabsWrap}>
                            <span>уникальность</span>
                            <span>анализ</span>
                            <span>экономия</span>
                        </div>
                    </div>

                    <div className={classes.cards}>
                        <div className={classes.left}>
                            <div className={classes.topLine}>
                                <div className={classes.originalBgc}>
                                    <div className={classes.originalContent}>
                                        <h5>
                                            Уникальность
                                        </h5>

                                        <p>
                                            Ваш бизнес будет <span>привлекателен</span> для клиентов, ценящих инновации и комфорт.
                                        </p>
                                    </div>
                                </div>

                                <div className={classes.economy}>
                                    <MediaQuery minWidth={769}>
                                    <h5>
                                        Экономия бюджета
                                    </h5>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={768}>
                                        <h5>
                                            Экономия
                                        </h5>
                                    </MediaQuery>
                                    <img
                                        src={economy}
                                        alt="economy"
                                    />
                                    
                                    <p>
                                        Стоимость разработки Telegram приложения ниже стоимости полноценного сайта.
                                    </p>
                                </div>
                            </div>

                            <div className={classes.analysis}>
                                <h5>
                                    Анализ клиентов
                                </h5>

                                <img
                                    src={anal}
                                    alt="anal"
                                    className={classes.analImg}
                                />

                                <p>
                                    Возможность сбора статистики о заинтересованных клиентах для более эффективного взаимодействия с вашей целевой аудиторией.
                                </p>
                            </div>

                            <div className={classes.additional}>
                                <h5>
                                    Дополнительный источник клиентов
                                </h5>

                                <p>
                                    Активное развитие Telegram открывает возможности для привлечения новых клиентов. <span>Укрепите позиции компании на рынке, увеличьте конкурентоспособность!</span>
                                </p>
                            </div>
                        </div>

                        <div className={classes.analysis}>
                            <h5>
                                Анализ клиентов
                            </h5>

                            <img
                                src={anal}
                                alt="anal"
                                className={classes.analImg}
                            />

                            <p>
                                Возможность сбора статистики о заинтересованных клиентах для более эффективного взаимодействия с вашей целевой аудиторией
                            </p>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default Advantages;
