import React from 'react';

export const ArrowRightIcon = (props) => {
    return (
        <svg
            viewBox="0 0 11 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.42939 20.2459L10.9013 10.4098L1.42939 0.57373L0.245605 1.80302L8.53378 10.4098L0.245605 19.0166L1.42939 20.2459Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ArrowRightIcon;
