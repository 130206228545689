const TelegramIcon = (props) => {
    return (
        <svg
            viewBox="0 0 96 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_843_158)">
                <path
                    d="M48 0C21.504 0 0 21.504 0 48C0 74.496 21.504 96 48 96C74.496 96 96 74.496 96 48C96 21.504 74.496 0 48 0ZM70.272 32.64C69.552 40.224 66.432 58.656 64.848 67.152C64.176 70.752 62.832 71.952 61.584 72.096C58.8 72.336 56.688 70.272 54 68.496C49.776 65.712 47.376 63.984 43.296 61.296C38.544 58.176 41.616 56.448 44.352 53.664C45.072 52.944 57.36 41.76 57.6 40.752C57.6333 40.5993 57.6289 40.4408 57.5871 40.2903C57.5452 40.1397 57.4673 40.0016 57.36 39.888C57.072 39.648 56.688 39.744 56.352 39.792C55.92 39.888 49.2 44.352 36.096 53.184C34.176 54.48 32.448 55.152 30.912 55.104C29.184 55.056 25.92 54.144 23.472 53.328C20.448 52.368 18.096 51.84 18.288 50.16C18.384 49.296 19.584 48.432 21.84 47.52C35.856 41.424 45.168 37.392 49.824 35.472C63.168 29.904 65.904 28.944 67.728 28.944C68.112 28.944 69.024 29.04 69.6 29.52C70.08 29.904 70.224 30.432 70.272 30.816C70.224 31.104 70.32 31.968 70.272 32.64Z"
                    fill="url(#paint0_linear_843_158)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_843_158"
                    x1="-9.21302e-07"
                    y1="77.28"
                    x2="376.984"
                    y2="87.6103"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0.0001"
                        stopColor="#0CFFAB"
                    />
                    <stop
                        offset="1"
                        stopColor="#F42BFF"
                    />
                </linearGradient>
                <clipPath id="clip0_843_158">
                    <rect
                        width="96"
                        height="96"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>

    );
}

export default TelegramIcon;
