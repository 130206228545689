import classes from "./Cases.module.scss";
import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation } from "swiper/modules";
import ArrowLeftIcon from "../ArrowLeftIcon/ArrowLeftIcon";
import ArrowRightIcon from "../ArrowRightIcon/ArrowRightIcon";
import cases1 from "../../static/images/cases1.png";
import cases2 from "../../static/images/cases2.png";
import cases3 from "../../static/images/cases3.png";
import cases4 from "../../static/images/cases4.png";
import cases5 from "../../static/images/cases5.png";
import cases6 from "../../static/images/cases6.png";
import cases7 from "../../static/images/cases7.png";
import cases8 from "../../static/images/cases8.png";
import cases9 from "../../static/images/cases9.png";
import slider1 from "../../static/images/slider1.png";
import slider2 from "../../static/images/slider2.png";
import slider3 from "../../static/images/slider3.png";
import slider4 from "../../static/images/slider4.png";
import slider5 from "../../static/images/slider5.png";
import slider6 from "../../static/images/slider6.png";
import slider7 from "../../static/images/slider7.png";
import slider8 from "../../static/images/slider8.png";
import slider9 from "../../static/images/slider9.png";
import slider10 from "../../static/images/slider10.png";
import slider11 from "../../static/images/slider11.png";
import slider12 from "../../static/images/slider12.png";


const Cases = () => {
    const imageCases = [
        {id:10, image: slider1},
        {id:11, image: slider2},
        {id:1, image: cases6},
        {id:2, image: cases2},
        {id:3, image: cases1},
        {id:8, image: cases4},
        {id:4, image: cases8},
        {id:7, image: cases7},
        {id:9, image: cases9},
        {id:12, image: slider3},
        {id:13, image: slider4},
        {id:14, image: slider5},
        {id:15, image: slider6},
        {id:16, image: slider7},
        {id:17, image: slider8},
        {id:18, image: slider9},
        {id:19, image: slider10},
        {id:20, image: slider11},
        {id:21, image: slider12},
    ]

    return (
        <div className={classes.Cases} id="portfolio">
            <h3 className={classes.heading}>
                Кейсы
            </h3>
            <Swiper
                modules={[Navigation]}
                loop={true}
                spaceBetween={20}
                slidesPerGroup={1}
                className={classes.swiperWrap}
                navigation={{ // Опции навигации
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                breakpoints={{
                    0: {
                        slidesPerView: 1.4,
                        spaceBetween: 30
                    },

                    768: {
                        slidesPerView: 4,
                        spaceBetween: 40
                    },
                }}
            >
                {imageCases.map(item =>
                    <SwiperSlide
                        className={classes.slide}
                        style={{backgroundImage: `url(${item.image})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'}}
                        key={item.id}
                    >
                        {/*<img src={item.image} className={classes.slideImage}/>*/}
                        {/*<div className={classes.iphone}> Slide 1 </div>*/}
                    </SwiperSlide>
                )}
                {imageCases.map(item =>
                    <SwiperSlide
                        className={classes.slide}
                        style={{backgroundImage: `url(${item.image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'}}
                        key={item.id}
                    >
                    </SwiperSlide>
                )}
            </Swiper>
            <div className={classes.navigation}>
                <button className={`${classes.roundedBtn} swiper-button-prev`}>
                    <ArrowLeftIcon className={classes.icon}/>
                </button>
                <button className={`${classes.roundedBtn} swiper-button-next`}>
                    <ArrowRightIcon className={classes.icon}/>
                </button>
            </div>
        </div>
    );
}

export default Cases;
