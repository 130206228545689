import React from 'react';

export const ArrowLeftIcon = (props) => {
    return (
        <svg
            viewBox="0 0 11 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9.57061 20.2459L0.0986557 10.4098L9.57061 0.57373L10.7544 1.80302L2.46622 10.4098L10.7544 19.0166L9.57061 20.2459Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ArrowLeftIcon;
