import classes from "./MainWorkDirection.module.scss";
import corporate from "../../static/images/corporate.png";
import application from "../../static/images/application.png";
import landing from "../../static/images/landing.png";
import application_mobile from "../../static/images/application_mobile.png";
import miniGames from "../../static/images/mini-games.png";
import shop from "../../static/images/shop.png";
import web3 from "../../static/images/web3.png";
import {useRef} from "react";
import Tilt from 'react-parallax-tilt';

const MainWorkDirection = () => {
    const reFon1 = useRef();
    const reFon2 = useRef();

    return (
        <div className={classes.MainWorkDirection} id="offers">
            <h3 className={classes.heading}>
                Направление <span>работ</span>
            </h3>

            <div className={`${classes.dFlex}  ${classes.direction}`}>
                <Tilt>
                    <div className={classes.corporate}>
                        <h6 className={classes.title}>
                            Корпоративные сайты
                        </h6>
                        <img
                            src={corporate}
                            alt="corporate"
                            className={classes.corporateImg}
                        />
                    </div>
                </Tilt>
                <div className={classes.dFlex}>
                    <div className={classes.middle}>
                        <div className={classes.fonBlock2}>
                            <div ref={reFon1} className={classes.fon1}></div>
                            <div ref={reFon2} className={classes.fon2}></div>
                        </div>
                        <Tilt>
                            <div className={classes.topMiddleCard}>
                                <h6 className={classes.title}>
                                    Лендинги
                                </h6>

                                <img
                                    src={landing}
                                    alt="landing"
                                    className={classes.landingImg}
                                />
                            </div>
                        </Tilt>
                        <Tilt>
                            <div className={classes.gradientBackground}>
                                <div className={classes.bottomCard}>
                                    <h6 className={classes.gradientTitle}>
                                        Мини- <br/>
                                        игры
                                    </h6>

                                    <img
                                        src={miniGames}
                                        alt="miniGames"
                                        className={classes.miniGamesImg}
                                    />
                                </div>
                            </div>
                        </Tilt>
                        <Tilt>
                            <div className={classes.gradientBackground}>
                                <div className={classes.bottomCard}>
                                    <h6 className={classes.gradientTitle}>
                                        Интернет- <br/>
                                        магазины
                                    </h6>

                                    <img
                                        src={shop}
                                        alt="shop"
                                        className={classes.shopImg}
                                    />
                                </div>
                            </div>
                        </Tilt>
                    </div>

                    <div className={classes.right}>
                        <div className={classes.fonBlock}>
                            <div ref={reFon1} className={classes.fon1}></div>
                            <div ref={reFon2} className={classes.fon2}></div>
                        </div>
                        <Tilt>
                            <div className={classes.gradientBackground}>
                                <div className={classes.topRightCard}>
                                    <h6 className={classes.gradientTitle}>
                                        Web3- <br/>
                                        решения
                                    </h6>

                                    <img
                                        src={web3}
                                        alt="web3"
                                        className={classes.web3Img}
                                    />
                                </div>
                            </div>
                        </Tilt>
                        <Tilt>
                            <div className={classes.bigBottomCard}>
                                <div className={classes.blackInnerBorder}>
                                    <div className={classes.bigBottomCardContent}>
                                        <h6>
                                            Приложения
                                        </h6>

                                        <img
                                            src={application}
                                            alt="application"
                                            className={classes.applicationImg}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Tilt>
                    </div>
                </div>
            </div>

            <div className={classes.mobileCards}>
                <div className={classes.first}>
                    <div className={classes.miniGames}>
                        <div className={classes.cardHeading}>
                            Мини-<br/>
                            игры
                        </div>

                        <img src={miniGames} alt=""/>
                    </div>
                    <div className={classes.internetMarket}>
                        <div className={classes.cardHeading}>
                            Интернет-<br/>
                            магазины
                        </div>

                        <img src={shop} alt=""/>
                    </div>
                </div>

                <div className={classes.corporateSite}>
                    <div className={classes.cardHeading}>
                        Корпоративные<br/>
                        сайты
                    </div>
                    <div className={classes.description} style={{width:'79rem', fontSize:'6rem', marginTop: '6rem'}}>
                        Веб-ресурсы для информации о компании, ее продуктах и контактах
                    </div>

                    <img
                        src={corporate}
                        alt="corporate"
                    />
                </div>

                <div className={classes.second}>
                    <div className={classes.web3}>
                        <div className={classes.cardHeading}>
                            Web3-решения
                        </div>

                        <img
                            src={web3}
                            alt="web3"
                        />
                    </div>
                    <div className={classes.application}>
                        <div className={classes.cardHeading}>
                            Приложения
                        </div>

                        <img
                            src={application_mobile}
                            alt=""
                        />
                    </div>
                </div>

                <div className={classes.landing}>
                    <img
                        src={landing}
                        alt=""
                    />

                    <div className={classes.text}>
                        <div className={classes.cardHeading}>
                            Лендинги
                        </div>
                        <div className={classes.description}>
                            Одностраничные сайты
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default MainWorkDirection;
