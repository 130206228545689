import React, {useRef, useState} from 'react';
import classes from "./Accordion.module.scss";


const ItemAccordion = ({text, isOpen}) =>{
    const refItem = useRef(null)
    const styles = [{
        opacity:1,
        height:refItem?.current?.scrollHeight,
        marginBottom: window.innerWidth > 768 ? '3.5rem' : '10rem'
    },{
        height:0,
        opacity:0,
        marginBottom: 0
    }]
    return (
        <div ref={refItem} className={classes.contentWrap} style={styles[isOpen?1:0]}>
            { text }
        </div>
    );
}

const Accordion = ({ items }) => {

    const [openIndex, setOpenIndex] = useState(null);

    return (
        <div className={classes.Accordion}>
            {items.map(item =>
                <div key={item.id}>
                    <div
                        className={classes.header}
                        onClick={() => {
                            setOpenIndex(openIndex === item.id ? null : item.id);
                        }}
                    >
                        <img
                            src={item.img}
                            alt={item.title}
                        />

                        <p className={`${classes.title} ${openIndex === item.id && classes.activeTitle}`}>
                            { item.title }
                        </p>
                        <div className={classes.icon}>
                            <div className={classes.horizontal}></div>

                            <div className={`${classes.vertical} ${openIndex === item.id && classes.verticalOff}`}></div>
                        </div>
                    </div>
                    <ItemAccordion text={item.content} isOpen={openIndex !== item.id}></ItemAccordion>
                    {item.id !== items.length &&
                        <div className={classes.borderBottom}></div>
                    }
                </div>
            )};
        </div>
    );
}

export default Accordion;
