import classes from "./TelegramWebAppComponent.module.scss";
import video from '../../static/images/video.mp4';
import {useRef} from "react";

const TelegramWebAppComponent = () => {
    const reFon1 = useRef();
    const reFon2 = useRef();
    return (
        <div className={classes.TelegramWebAppComponent}>
            <h3 className={classes.heading}>
                Telegram <span>Web Apps</span>
            </h3>
            <div className={classes.cards}>
                <div className={classes.headLine}>
                    <video autoPlay muted loop playsInline className={classes.video}>
                        <source
                            src={video}
                            type="video/mp4"
                        />
                    </video>
                    <div className={classes.tabsWrap}>
                                    <span>эффективность</span>
                                    <span>комфорт</span>
                    </div>
                </div>      
               
                <div className={classes.text}>
                    <div className={classes.gradientBackground}>
                        <h5 className={classes.gradientTitle}>
                            {/* Ощутите полную свободу и гибкость с Telegram Web Apps! */}
                            Новый уровень комфорта <br/>
                            и эффективности бизнеса!
                        </h5>
                    </div>
                    <div className={classes.right}>
                        <div className={classes.fonBlock}>
                                <div ref={reFon1} className={classes.fon1}></div>
                                <div ref={reFon2} className={classes.fon2}></div>
                        </div>
                    </div>
                    <div className={classes.gradientBackgroundForText}>
                        <div className={classes.description}>
                            <span>Web Apps</span> — это современные мини-приложения,
                            которые работают внутри Telegram.<br/>
                        </div>
                        <div className={classes.description}>
                            Не тратьте свое время на скачивание и установку
                            отдельных приложений. <span>Интернет-магазины, игры,
                            сервисы</span> — все это может быть интегрировано
                            внутри вашего любимого мессенджера.<br/>
                        </div>
                        <div className={classes.description}>
                            Web Apps — это мощный инструмент для
                            <span> оптимизации</span> и <span>повышения эффективности </span>
                            вашего бизнеса.<br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TelegramWebAppComponent;
