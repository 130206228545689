import './App.css';
import {useTelegram} from "./hooks/useTelegram.js";
import {track_newUser, track_userSendMessage} from "./utils/statistics.js";
import { useCallback, useEffect } from 'react';
import Hero from './components/hero/hero.jsx';
import TheHeader from './components/TheHeader/TheHeader.jsx';
import MainWorkDirection from './components/MainWorkDirection/MainWorkDirection.jsx';
import Cases from './components/Cases/Cases';
import TheFooter from './components/TheFooter/TheFooter';
import WorkWithUs from './components/WorkWithUs/WorkWithUs';
import TelegramWebAppComponent from './components/TelegramWebAppComponent/TelegramWebAppComponent';
import AdvantagesBlock from "./components/AdvantagesBlock/AdvantagesBlock";

import 'swiper/css';
import './fonts/featuremono.css'
import Temp from "./temp/temp";

function App() {
  const {webApp, user, lang} = useTelegram();

  const sendTrack = async ()=>{
    if(webApp.initDataUnsafe?.user?.id !== undefined){
      const res = await track_newUser(
          webApp.initDataUnsafe?.user?.first_name+' '+webApp.initDataUnsafe?.user?.last_name,
          webApp.initDataUnsafe?.user?.username,
          webApp.initDataUnsafe?.user?.id
      );
    }
  }

 const sendMsgTrack = async ()=>{
  if(webApp.initDataUnsafe?.user?.id !== undefined){
    const res = await track_userSendMessage(
        webApp.initDataUnsafe?.user?.id
    );
  }
}
  
 
  const onMainClick = useCallback(() => {
    sendMsgTrack();  
    window.open('https://t.me/proself');
  }, []);

  useEffect(() => {
    webApp.ready();
    webApp.MainButton.setText("Написать нам");
    webApp.MainButton.onClick(onMainClick)
    webApp.MainButton.show();
    sendTrack()
    return () => {
      webApp.MainButton.offClick(onMainClick)
    };
    
  }, [webApp]);

  console.log(window.location.pathname)
  if(window.location.pathname === '/love'){
      return (
          <Temp/>
      )
  }
  return (
    <div className={'container'}>
        <TheHeader/>
        <Hero/>
        <TelegramWebAppComponent/>
        <MainWorkDirection/>
        <Cases/>
        <AdvantagesBlock/>
        <WorkWithUs/>
        {user ? null : <TheFooter/>} 
    </div>
  );
}

export default App;
