import classes from "./TheHeader.module.scss";
import {useTelegram} from "../../hooks/useTelegram";
import logo from "../../static/images/logo.png"
import {text} from "../../translate";

const TheHeader = () => {
    const { user, lang } = useTelegram();
    const menu = [
        {
            id: 0,
            name: text[lang].header.name1,
            hash: '#offers',
        },
        {
            id: 1,
            name: text[lang].header.name2,
            hash: '#portfolio',
        },
        {
            id: 2,
            name: text[lang].header.name3,
            hash: '#advantages',
        },
        {
            id: 3,
            name: text[lang].header.name4,
            hash: '#contacts',
        },
    ];
    return (
        <div className={classes.header}>
            <svg className={classes.logo} width="145" height="71" viewBox="0 0 145 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="17.1618" cy="35" rx="17.1618" ry="17" fill="white"/>
                <path d="M20.3141 47.1429L29.4204 38.1225L20.3141 38.1225L20.3141 47.1429Z" fill="#AF34F5"/>
                <ellipse cx="31.1714" cy="19.0408" rx="2.45169" ry="2.42857" fill="white"/>
                <ellipse cx="2.45169" cy="49.5715" rx="2.45169" ry="2.42857" fill="white"/>
                <ellipse cx="42.0289" cy="2.04083" rx="1.40097" ry="1.38776" fill="white"/>
                <ellipse cx="88.9615" cy="61.0204" rx="1.40097" ry="1.38776" fill="white"/>
                <ellipse cx="34.3239" cy="8.97958" rx="6.30435" ry="6.2449" fill="white"/>
                <ellipse cx="118.031" cy="37.7755" rx="2.45169" ry="2.42857" fill="white"/>
                <ellipse cx="83.7076" cy="55.1225" rx="3.85266" ry="3.81633" fill="white"/>
                <path d="M118.382 52L127.488 42.9796L118.382 42.9796L118.382 52Z" fill="#AF34F5"/>
                <path d="M46.9324 15.2245L61.6426 0.65306L46.9324 0.653059L46.9324 15.2245Z" fill="#AF34F5"/>
                <path d="M20.3138 70.0408L35.0239 55.4694L20.3138 55.4694L20.3138 70.0408Z" fill="#AF34F5"/>
                <path d="M43.6653 34.6531H40.4879V18.1665H50.6505L53.601 21.1641V25.6604L50.6505 28.658H43.6653V34.6531ZM43.6653 21.314V25.5105H49.3392L50.4236 24.3865V22.438L49.3392 21.314H43.6653ZM68.7215 21.1641V25.6604L65.872 28.4082H64.3589L68.5702 32.4549V34.6531H66.3763L60.3241 28.4082H58.7859V34.6531H55.6085V18.1665H65.7711L68.7215 21.1641ZM64.4598 25.5105L65.5441 24.3865V22.438L64.4598 21.314H58.7859V25.5105H64.4598ZM74.1082 34.6531L70.4769 30.7812V22.0384L74.1082 18.1665H79.9587L83.59 22.0384V30.7812L79.9587 34.6531H74.1082ZM73.6543 23.2624V29.5572L75.4952 31.5056H78.5717L80.4126 29.5572V23.2624L78.5717 21.314H75.4952L73.6543 23.2624ZM89.1027 34.6531L85.6731 31.7305L85.5975 29.1326L88.8001 29.0826L88.8505 30.2816L90.1366 31.4557L94.121 31.5056L95.4827 30.3566L95.5331 28.9827L94.1714 27.8336L89.2288 27.8087L85.7992 25.3607L85.7236 21.1891L88.9514 18.2415L95.1549 18.1665L98.5844 21.0891L98.6601 23.687L95.4323 23.737L95.407 22.538L94.121 21.3639L90.3131 21.314L88.9514 22.463L88.901 23.712L90.1618 24.7362L95.2053 24.7861L98.6349 27.334L98.7105 31.6305L95.4827 34.5781L89.1027 34.6531ZM113.831 18.1665V21.314H104.148V24.5613H112.318V27.6838H104.148V31.5056H113.831V34.6531H100.97V18.1665H113.831ZM129.456 34.6531H116.091V18.1665H119.344V31.5056H129.456V34.6531ZM144.577 18.1665V21.0891H134.238V24.8611H143.064V28.0335H134.238V34.6531H131.212V18.1665H144.577Z" fill="white"/>
                <path d="M49.9698 52H43.8671L40.2358 48.1282V39.3853L43.8671 35.5135H49.9698L53.4498 39.2105L50.7515 40.959L48.5828 38.6609H45.2541L43.4132 40.6093V46.9042L45.2541 48.8526H48.5828L50.4237 46.9042V45.5053H46.6663V42.4078H53.6011V48.1282L49.9698 52ZM68.7216 38.511V43.0073L65.872 45.7551H64.359L68.5703 49.8018V52H66.3764L60.3242 45.7551H58.786V52H55.6086V35.5135H65.7712L68.7216 38.511ZM64.4599 42.8575L65.5442 41.7334V39.785L64.4599 38.6609H58.786V42.8575H64.4599ZM74.1083 52L70.477 48.1282V39.3853L74.1083 35.5135H79.9588L83.5901 39.3853V48.1282L79.9588 52H74.1083ZM73.6544 40.6093V46.9042L75.4953 48.8526H78.5718L80.4127 46.9042V40.6093L78.5718 38.6609H75.4953L73.6544 40.6093ZM95.0793 52H89.2289L85.5976 48.1282V35.5135H88.775V46.9042L90.6158 48.8526H93.6924L95.5332 46.9042V35.5135H98.7106V48.1282L95.0793 52ZM104.148 52H100.97V35.5135H111.133L114.084 38.511V43.0073L111.133 46.0049H104.148V52ZM104.148 38.6609V42.8575H109.822L110.906 41.7334V39.785L109.822 38.6609H104.148Z" fill="white"/>
            </svg>
            <ul className={classes.menu}>
                {menu.map(item =>
                    (item.id !== 3 || !user) && (
                        <li
                            key={item.id}
                            className={`${classes.item} ${item.id === 3 && classes.contacts}`}
                        >
                            <a href={item.hash} className={classes.link}>
                                { item.name }
                            </a>
                        </li>
                    )
                )}
            </ul>
        </div>
    );
}

export default TheHeader;
