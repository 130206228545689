export const text = {
    'en': {
        header: {
            name1: 'Services',
            name2: 'Portfolio',
            name3: 'Advantages',
            name4: 'Contacts',
        }
    },
    'ru': {
        header: {
            name1: 'Услуги',
            name2: 'Портфолио',
            name3: 'Преимущества',
            name4: 'Контакты',
        }
    }
}