const WhatsUpIcon = (props) => {
    return (
        <svg
            viewBox="0 0 97 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_843_160)">
                <path
                    d="M89.7169 22.2181C75.379 -0.0090907 46.0172 -6.59491 23.3098 7.19415C1.1513 20.9832 -5.98334 51.031 8.35454 73.1896L9.52078 74.9732L4.71862 92.9471L22.6924 88.1449L24.4761 89.3111C32.2281 93.4959 40.6662 95.8969 49.0357 95.8969C58.0226 95.8969 67.0095 93.4959 74.7616 88.6937C96.9201 74.2872 103.506 44.8568 89.7169 22.0809V22.2181ZM77.1627 68.456C74.7616 72.0233 71.7431 74.4244 67.5583 75.0418C65.1573 75.0418 62.1388 76.2081 50.202 71.4745C40.0488 66.6724 31.6107 58.8517 25.6423 49.8648C22.075 45.6801 20.2227 40.2605 19.6739 34.8409C19.6739 30.0387 21.4576 25.854 24.4761 22.8355C25.6423 21.6692 26.8772 21.0518 28.0434 21.0518H31.0619C32.2281 21.0518 33.463 21.0518 34.0804 23.4529C35.2467 26.4714 38.2652 33.6746 38.2652 34.2921C38.8826 34.9095 38.6082 39.5058 35.8641 42.1127C34.3548 43.8278 34.0804 43.8964 34.6978 45.1312C37.0989 48.6986 40.1174 52.3345 43.0673 55.353C46.6346 58.3715 50.2706 60.7726 54.4553 62.5562C55.6215 63.1736 56.8564 63.1736 57.4738 61.9388C58.0912 60.7726 61.0411 57.7541 62.276 56.5192C63.4422 55.353 64.0596 55.353 65.2945 55.9018L74.8988 60.704C76.065 61.3214 77.2999 61.8702 77.9173 62.4876C78.5347 64.2713 78.5347 66.6724 77.2999 68.456H77.1627Z"
                    fill="url(#paint0_linear_843_160)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_843_160"
                    x1="-136.279"
                    y1="77.1968"
                    x2="234.975" y2="87.3811"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0.388524"
                        stopColor="#0CFFAB"
                    />
                    <stop
                        offset="1"
                        stopColor="#F42BFF"
                    />
                </linearGradient>
                <clipPath id="clip0_843_160">
                    <rect
                        width="96"
                        height="96"
                        fill="white"
                        transform="translate(0.914062)"
                    />
                </clipPath>
            </defs>
        </svg>

    );
}

export default WhatsUpIcon;
