import React, {useEffect, useState} from 'react';
import './FlipCountdown.css';
import img from './img.jpeg'

const FlipUnit = ({ unit, value }) => {
    const [prevValue, setPrevValue] = useState(value);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (value !== prevValue) {
            setAnimate(true);
            const timeout = setTimeout(() => {
                setAnimate(false);
                setPrevValue(value);
            }, 600); // длительность анимации (600мс)
            return () => clearTimeout(timeout);
        }
    }, [value, prevValue]);

    return (
        <div className="flip-unit">
            <div className="flip-label">{unit}</div>
            <div className="flip-container">
                <div className="flip-card">
                    {/* Передняя сторона карточки – предыдущие значение */}
                    <div className="card-face front">
                        {prevValue}
                    </div>
                    {/* Задняя сторона карточки – новое значение, анимированное при изменении */}
                    <div className={`card-face back ${animate ? 'animate' : ''}`}>
                        {value}
                    </div>
                </div>
            </div>
        </div>
    );
};

const Temp = () => {
// Устанавливаем целевую дату: 17 апреля 2025 года 13:25:00
    const targetDate = new Date(2025, 3, 17, 13, 25, 0);

    // Функция для расчёта оставшегося времени
    const calculateTimeLeft = () => {
        const now = new Date();
        const difference = targetDate - now;
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                'дней': Math.floor(difference / (1000 * 60 * 60 * 24)),
                'часов': Math.floor((difference / (1000 * 60 * 60)) % 24),
                'минут': Math.floor((difference / (1000 * 60)) % 60),
                'секунд': Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        // Обновляем таймер каждую секунду
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(timer);
    }, []);

    const timerComponents = [];
    Object.keys(timeLeft).forEach(interval => {
        timerComponents.push(
            <span key={interval}>
        {timeLeft[interval]} {interval}{' '}
      </span>
        );
    });

    return (
        <div style={{
            color:"white",
            display:'flex',
            width:'100%',
            height:'100vh',
            alignItems:'center',
            justifyContent:'center',
            flexDirection:'column',
            fontSize:'20px',
            fontFamily: "'Courier New', Courier, monospace'",
            minWidth: '100%'
        }}>
            <div style={{ marginBottom: '20px' }}>До приезда любимой осталось:</div>
            <div style={{ display: 'flex', gap: '20px' }}>
                {Object.keys(timeLeft).map((unit) => (
                    <FlipUnit key={unit} unit={unit} value={timeLeft[unit]} />
                ))}
            </div>
            <img src={img} alt={''} style={{width:'40vh', marginTop:'20px'}}/>
        </div>
    );

};

export default Temp;